.chakra-input {
    border-color: gray !important;

  }
.headers{
    font-size: 30px;
    background-color: rgba(0,0 ,0,0.8);
    text-align: center;
    color: white;
    padding: 10px;
    height: 70px;
}
span{
    font-weight: 600;
}
.form {
    position: relative;
    background-image: url('./bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

.form-details{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
.details{
    color: white;
}
.sidetext{
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 20px;
}
#texticons{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.formIcons img{
    filter: invert(1);
    height: 45px;
}

