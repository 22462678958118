.Cimgs {
  position: relative;
  overflow: hidden;
  color: white;
}

.card{
  height: 100%;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background-color: rgba(5, 5, 54, 0.8);
}
.Cimgs::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 5, 54, 0.8);
  mix-blend-mode: overlay;
  z-index: 0;
  pointer-events: none; /* Ensure click events pass through the overlay */
}

.course-content {
  padding: 100px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Distribute course cards evenly */
}

.course-card {
  border-radius: 5%;
  width: 300px;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}

.course-card img {
  border-radius: 5%;
  height: 200px;
}

.course-details {
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 140px;
  border-radius: 3%;
  padding: 5px;
}

.pricebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 3%;
  height: 25px;
  font-weight: 600;
}

.pricebtn :nth-child(3) {
  text-decoration: line-through;
}
