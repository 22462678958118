.videoandtext{
    padding-top: 10px;
    background-color: black;
}
.missiontext{
    background-color: black;
    padding: 50px;
    text-align: center;
    font-size: 20px;
    color: white;
}
.missiontext h2{
    font-weight: 600;
    font-size: 35px;
}
.missiontext h2 span{
    color: #00f381;
}