.Our_Philosopny{
    background-color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    padding-bottom: 30px;
}
#Hline{
    color: white;
    background-color: black;
    width: 100%;
    text-align: center;
    height: 70px;
    font-size: 40px;
}
#Hline span{
    color: #00f381;
}
.top_img{
    padding: 45px;
    display: flex;
    gap: 85px;
}
.bottom_img{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    gap: 85px;
    text-align: center;
}

@media only screen and (max-width: 480px) {
    .top_img img{
        height: 100px;
    }
    .top_img{
        padding: 15px;
        gap: 25px;
    }
    .smlogo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bottom_img{
        padding-top: 25px;
        font-size: 10px;
        gap: 10px;
        text-align: center;
    }
    .bottom_img img{
        height: 40px;
    }
}
  
@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .top_img img{
        height: 150px;
    }
    .top_img{
        padding: 15px;
        gap: 25px;
    }
    .smlogo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bottom_img{
        padding-top: 25px;
        font-size: 10px;
        gap: 10px;
        text-align: center;
    }
    .bottom_img img{
        height: 70px;
    }
  }

@media only screen and (min-width: 1025px) {
    /* Styles for large devices here */
  }
  

