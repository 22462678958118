.sec2imgs{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.sec2imgs img{
    height: 420px;
}

@media only screen and (max-width: 320px) {
    .sec2imgs {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .sec2imgs img {
        height: 100px;
        width: 150px;
        max-width: 100%;
    }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
    .sec2imgs {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .sec2imgs img {
        height: 150px;
        width: 150px;
        max-width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sec2imgs {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .sec2imgs img {
        height: 250px;
        width: 300px;
        max-width: 100%;
    }
}