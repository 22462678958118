.chakra-input {
    border-color: gray !important;

  }
.form-details{
    background-image: url('./bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 550px;
}
.sidetext{
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 20px;
}
#texticons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

